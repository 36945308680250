@use 'variables' as *;

@mixin mobile {
    @media screen and (max-width: ($tablet-breakpoint - 1)) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: $tablet-breakpoint) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $desktop-breakpoint) {
        @content;
    }
}