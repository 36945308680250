.audioplayer {
    width:100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    // background-color: #fff;
    background: #3697ab;
    opacity: 100%;
    height: 115px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0.5rem;
    border: 1px solid rgb(97, 146, 156); // #17a8b5 
    z-index: 1;
}

.figure {
    margin: 0rem;
    position: fixed;

    &__caption {
        color: white;
    }

    &__caption-details {
        color: white;
        margin-left: 9rem;
    }
}